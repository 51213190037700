<template>
  <div>
    <div class="text-center" v-if="loading">
      <CSpinner color="primary" size="lg" />
    </div>

    <CRow class="align-items-center mb-3" v-if="product">
      <CCol col="auto" class="mt-2 mb-1 my-sm-0">
        <CButton color="outline-primary" @click="onBack()">
          <font-awesome-icon icon="arrow-left" />
        </CButton>
      </CCol>
      <CCol sm>
        <strong class="text-gray-700"
          >{{ marketName(market) }} / {{ productCategoryParent() }} /
          {{ productCategory() }}</strong
        >
        <div class="ui-type-display-lg">
          {{ productName(product.pp, lang) }}
        </div>
      </CCol>
      <CCol col="auto" class="ml-auto text-right">
        <MarketCartButton />

        <div class="ui-type-caption text-gray-600">
          Ordine minimo
          <strong>{{ minAmountToOrder(market, currencySymbol) }}</strong>
        </div>
      </CCol>
    </CRow>

    <CCard v-if="product">
      <CCardBody>
        <CRow>
          <CCol>
            <CRow>
              <CCol :lg="{ size: '6', order: '2' }" class="mb-3">
                <div class="ui-type-caption text-uppercase text-gray-600">
                  {{ producerName() }}
                </div>
                <div class="ui-type-display-sm mb-3">
                  {{ productName(product.pp, lang) }}
                </div>

                <CSelect
                  v-if="productHasManyVariants(product.lmv)"
                  label="Seleziona variante"
                  addInputClasses="d-block w-auto"
                  :value="variantIdSelected"
                  @update:value="onVariantChange"
                  :options="variantsListOptions()"
                  custom
                />
                <p v-else>{{ pruductVariatName(currentVariantPpl, lang) }}</p>

                <div
                  class="ui-type-display-md font-weight-bold"
                  v-if="currentVariant"
                >
                  {{ pruductVariatPrice() }}
                  <sup>{{ currencySymbol }}</sup>
                  <span
                    class="ml-2 text-warning"
                    v-if="needPriceCorrection(product.pp, currentVariantPpl)"
                  >
                    <font-awesome-icon icon="balance-scale" />
                  </span>
                </div>
                <div
                  class="ui-type-subheading text-gray-600"
                  v-if="productVariantHasPriceBaseUnit()"
                >
                  {{ productVariantPriceBaseUnit() }}
                </div>

                <hr />

                <table class="w-100">
                  <tr v-if="productHasDietInfo(product.pp)">
                    <th>Adatto a diete</th>
                    <td>{{ productDietInfo(product.pp) }}</td>
                  </tr>
                  <tr v-if="productHasCertifications(product.pp)">
                    <th>Certificazioni</th>
                    <td>{{ productCertifications(product.pp) }}</td>
                  </tr>
                  <tr v-if="productVariantHasOrigin()">
                    <th>Origine</th>
                    <td>{{ productVariantOrigin() }}</td>
                  </tr>
                  <tr
                    v-if="
                      productVariantHasGuaranteedDuration(currentVariantPpl)
                    "
                  >
                    <th>Durata garantita</th>
                    <td>
                      {{ productVariantGuaranteedDuration(currentVariantPpl) }}
                    </td>
                  </tr>
                </table>
              </CCol>
              <CCol lg="5" class="mb-3">
                <flickity
                  ref="mainFlickity"
                  :options="flickityProduct"
                  class="flickity-product mb-2"
                >
                  <figure
                    class="flickity-figure"
                    v-for="(image, index) in images"
                    :key="`c_${image.key}`"
                  >
                    <img
                      class="flickity-img"
                      :src="image.cropped"
                      width="900"
                      height="600"
                      alt=""
                    />

                    <div class="flickity-zoom">
                      <CButton
                        color="primary"
                        @click.prevent="onImageClick(image, index)"
                      >
                        <font-awesome-icon icon="search" />
                      </CButton>
                    </div>
                  </figure>
                </flickity>
                <flickity
                  ref="navFlickity"
                  :options="flickityProductAsNavFor"
                  class="flickity-product-asnavfor"
                >
                  <figure
                    class="flickity-figure"
                    v-for="image in images"
                    :key="`t_${image.key}`"
                  >
                    <img
                      class="flickity-img"
                      :src="image.thumb"
                      width="68"
                      height="68"
                      alt=""
                    />
                  </figure>
                </flickity>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <div
                  class="font-weight-bold mb-2"
                  v-if="productDescription(product.pp, lang)"
                >
                  Descrizione
                </div>
                <div
                  class="ui-type-body"
                  v-html="productDescription(product.pp, lang)"
                ></div>
              </CCol>
            </CRow>
            <hr class="my-4" />
            <CRow>
              <CCol md="3" v-if="producerImageSrc()">
                <figure class="mb-0">
                  <img :src="producerImageSrc()" alt="" class="img-fluid" />
                </figure>
              </CCol>
              <CCol>
                <div class="ui-type-caption text-uppercase text-gray-600">
                  Produttore
                </div>
                <div class="ui-type-display-sm">
                  {{ producerName() }}
                </div>
                <div class="ui-type-body mb-2">
                  <a href="#0" v-html="producerInfo()"></a>
                </div>
                <div class="ui-type-body" v-html="producerDescription()"></div>
              </CCol>
            </CRow>
          </CCol>
          <CCol lg="auto">
            <CCard class="mb-0" style="max-width: 12rem">
              <CCardBody>
                <div class="mb-2">
                  <div class="text-primary">
                    Venduto da {{ marketName(market) }}
                  </div>

                  <div class="mr-1">
                    Ordina entro
                    <strong class="text-secondary">{{
                      timelimitToOrder(market)
                    }}</strong>
                  </div>

                  <div class="mr-1" v-if="showDelivery(market)">
                    Consegneremo <strong>{{ deliveryDate(market) }}</strong>
                  </div>

                  <div class="mr-1" v-if="showPickup(market)">
                    Ritiralo
                    <strong>{{ pickupDate(market) }}</strong>
                  </div>

                  <div class="mr-1" v-if="showPickup(market)">
                    <a href="#0" @click.prevent="showMarketDeliveryMap = true"
                      >nei nostri punti di ritiro</a
                    >
                  </div>
                </div>

                <MarketDeliveryMapModal
                  v-if="showMarketDeliveryMap"
                  :show.sync="showMarketDeliveryMap"
                  :market="market.market"
                  :familyId="familyId"
                  :title="`${marketName(market)} - Punti di ritiro`"
                />

                <CInput
                  label="Quantità"
                  :append="suffix()"
                  v-model="variantQuantity"
                  :disabled="!enableAddToCartButton()"
                  type="number"
                  min="0"
                  :step="step()"
                />
                <CButton
                  color="primary"
                  :block="true"
                  :disabled="!enableAddToCartButton()"
                  @click="addToCart(variantQuantity)"
                >
                  <font-awesome-icon icon="shopping-cart" class="mr-1" />
                  {{ currentVariantInCart ? "AGGIORNA" : "AGGIUNGI" }}
                </CButton>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard v-if="product">
      <CCardBody>
        <div class="d-flex justify-content-center" v-if="productsLoading">
          <CSpinner color="info" />
        </div>

        <flickity
          :options="flickityProducts"
          class="flickity-products"
          v-if="products.length > 0"
        >
          <CCol
            class="flickity-col"
            v-for="product in products"
            :key="product.id"
          >
            <MarketProductCard v-bind="product" @onClick="onCardClick" />
          </CCol>
        </flickity>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { Query, Predicate } from "@syncfusion/ej2-data";
import { Fancybox } from "@fancyapps/ui";
import get from "lodash/get";
import { GetDataManagerNew, ApiCall } from "../../ds";
import { mapState, mapGetters } from "vuex";
import { MarketCartButton } from "./partials";
import {
  formatPrice,
  getCartItemByPpmId,
  getOptionLabel,
  getProductCardDataFromMarketProduct,
  getTranslation,
  galleryImage,
} from "../../helpers/common";
import { getPriceUnitOptions, getPwOptions } from "../../helpers/options";
import marketMixin from "../../mixins/market";
import productMixin from "../../mixins/product";
import { MarketProductCard, MarketDeliveryMapModal } from "./partials";

export default {
  name: "UiMarketProduct",

  components: {
    MarketCartButton,
    MarketProductCard,
    MarketDeliveryMapModal,
  },

  mixins: [marketMixin, productMixin],

  data() {
    return {
      loading: false,
      product: null,
      variantIdSelected: null,
      variantQuantity: 0,
      flickityProduct: {
        pageDots: false,
        prevNextButtons: false,
        imagesLoaded: true,
      },
      flickityProductAsNavFor: {
        asNavFor: ".flickity-product",
        cellAlign: "left",
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        imagesLoaded: true,
      },
      productsLoading: false,
      products: [],
      flickityProducts: {
        imagesLoaded: true,
        pageDots: false,
        cellAlign: "left",
        groupCells: true,
      },
      showMarketDeliveryMap: false,
    };
  },

  mounted() {
    const self = this;
    this.getProduct().then((prod) => {
      self.getProducts(prod);
    });
  },

  watch: {
    currentVariant(value) {
      const { cart } = this;
      if (value && cart) {
        const v = getCartItemByPpmId(cart, get(value, "ppm.id"));
        this.variantQuantity = v ? v.qta : 0;
      }
    },
  },

  computed: {
    ...mapState("shop", ["market", "cart"]),

    ...mapGetters("connections", ["currencySymbol", "familyId"]),

    lang() {
      return this.$i18n.locale;
    },

    currentVariant() {
      if (this.product !== null && this.variantIdSelected !== null) {
        return this.product.lmv.find(
          (v) => v.ppl.id === this.variantIdSelected
        );
      }
      return undefined;
    },

    currentVariantPpl() {
      if (this.product !== null && this.variantIdSelected !== null) {
        const v = this.product.lmv.find(
          (v) => v.ppl.id === this.variantIdSelected
        );
        if (v) {
          return v.ppl;
        }
      }
      return undefined;
    },

    currentVariantInCart() {
      const { currentVariant, cart } = this;
      if (currentVariant && cart) {
        return !!getCartItemByPpmId(cart, get(currentVariant, "ppm.id"));
      }
      return false;
    },

    images() {
      const images = [];
      if (this.product !== null) {
        const { id, image, image_label } = this.product.pp;
        if (image) {
          images.push(galleryImage(image, id, "product"));
        }
        if (image_label) {
          images.push(galleryImage(image_label, id, "product"));
        }
        // variants
        this.product.lmv.forEach((variant) => {
          const { id, image, image_label } = variant.ppl;
          if (image) {
            images.push(galleryImage(image, id, "variant"));
          }
          if (image_label) {
            images.push(galleryImage(image_label, id, "variant"));
          }
        });
      }
      return images;
    },
  },

  methods: {
    getProduct() {
      const self = this;
      const familyId = this.$route.params.familyId;
      const marketId = this.$route.params.marketId;
      const productId = this.$route.params.productId;
      const predicate = new Predicate("pp.id", "equal", productId);

      self.loading = true;

      return ApiCall(
        GetDataManagerNew("family_market_products", [familyId, marketId]),
        new Query().where(predicate),
        function (e) {
          const result = e.result[0];
          self.product = result;
          if (self.productHasOneVariant(self.product.lmv)) {
            self.variantIdSelected = self.product.lmv[0].ppl.id;
          }
          self.loading = false;
          return result;
        }
      );
    },

    getProducts(prod) {
      const self = this;
      const familyId = this.$route.params.familyId;
      const marketId = this.$route.params.marketId;
      const lang = this.$i18n.locale;
      const currencySymbol = this.currencySymbol;
      const unitOptions = getPriceUnitOptions();

      const dm = GetDataManagerNew("family_market_products", [
        familyId,
        marketId,
      ]);

      this.productsLoading = true;

      const productId = prod.pp.id;
      const producerId = prod.pp.producer.id;

      let predicate = new Predicate("id", "notequal", productId).and(
        "pp.producer.id",
        "equal",
        producerId
      );

      const query = new Query().where(predicate).take(10);

      return ApiCall(
        dm,
        query,
        (e) => {
          const result = e.result;
          self.products = result.map((product) => {
            return getProductCardDataFromMarketProduct(
              product,
              lang,
              currencySymbol,
              unitOptions
            );
          });
          self.productsLoading = false;
          return result;
        },
        (e) => {
          self.productsLoading = false;
        }
      );
    },

    onBack() {
      const familyId = this.$route.params.familyId;
      const marketId = this.$route.params.marketId;
      this.$router.push({
        name: "UserMarketDetail",
        params: { familyId: familyId, marketId: marketId },
      });
    },

    onCardClick(productId) {
      const familyId = this.$route.params.familyId;
      const marketId = this.$route.params.marketId;
      this.$router.push({
        name: "UserMarketProduct",
        params: { familyId, marketId, productId },
      });
    },

    onVariantChange(value) {
      this.variantIdSelected = value;
      if (value !== null) {
        this.selectImageByVariantId(parseInt(value, 10));
      }
    },

    selectImageByVariantId(varantId) {
      const index = this.images.findIndex((img) => {
        return img.refId === varantId && img.type === "variant";
      });
      if (index > -1) {
        this.$refs.mainFlickity.select(index);
      }
    },

    productCategoryParent() {
      const { translations } = this.product.pp.category.parent;
      return getTranslation(translations, this.lang);
    },

    productCategory() {
      const { translations } = this.product.pp.category;
      return getTranslation(translations, this.lang);
    },

    pruductVariatPrice() {
      return this.currentVariant && this.currentVariant.ppm
        ? formatPrice(this.currentVariant.ppm.price)
        : "--";
    },

    productVariantHasPriceBaseUnit() {
      return this.currentVariant && this.currentVariant.price_base_unit;
    },

    productVariantPriceBaseUnit() {
      const unitOptions = getPriceUnitOptions();
      if (this.currentVariant && this.currentVariant.price_base_unit) {
        const { price, unit } = this.currentVariant.price_base_unit;
        return formatPrice(
          price,
          `${this.currencySymbol} / ${getOptionLabel(unit, unitOptions)}`
        ).format();
      }
      return "--";
    },

    productCertifications() {
      return this.product.pp.certifications
        .map((cert) => {
          return cert.name;
        })
        .join(", ");
    },

    isWeightProduct() {
      this.product.pp.pw === true;
    },

    producerName() {
      return this.product.pp.producer.role.name;
    },

    producerImageSrc() {
      return get(this.product, "pp.producer.role.logo_rect.image_sm");
    },

    producerInfo() {
      const { addresses } = this.product.pp.producer.role.family;
      return addresses
        .map((a) => {
          return `${a.address} ${a.city}, ${a.postal_code}, ${a.country}`;
        })
        .join("<br />");
    },

    producerDescription() {
      return this.product.pp.producer.role.desc_short;
    },

    productVariantHasOrigin() {
      return this.currentVariant && this.currentVariant.ppl.production_type;
    },

    productVariantOrigin() {
      const { translations } = this.currentVariant.ppl.production_type;
      return getTranslation(translations, this.lang);
    },

    suffix() {
      const { pp } = this.product;
      const pw_option = get(pp, "extra.pw_option", 1);
      if (pw_option === 3) {
        return `${getOptionLabel(pp.pw_unit, getPriceUnitOptions())}`;
      } else {
        return `${getOptionLabel(pw_option, getPwOptions(), "plural")}`;
      }
    },

    step() {
      const isWeightProduct = get(this.product, "pp.pw") === true;
      return isWeightProduct ? "0.1" : "1";
    },

    variantsListOptions() {
      const lang = this.lang;
      return [
        { value: null, label: "- seleziona -" },
        ...this.product.lmv.map((variant) => {
          return {
            value: variant.ppl.id,
            label: getTranslation(variant.ppl.translations, lang),
          };
        }),
      ];
    },

    enableAddToCartButton() {
      return this.currentVariant && this.currentVariant.ppm;
    },

    addToCart(quantity) {
      const ppm_id = this.currentVariant.ppm.id;

      this.$store.dispatch("shop/setCartQuantity", {
        items: [{ ppm_id, quantity }],
      });
    },

    needPriceCorrection() {
      return (
        this.product.pp.pw ||
        (this.currentVariant && this.currentVariant.ppl.pw === true)
      );
    },

    onImageClick(image, index) {
      const slideshow = this.images.map((img) => {
        return { src: img.full, type: "image" };
      });
      const instance = new Fancybox(slideshow);
      instance.jumpTo(index);
    },
  },
};
</script>
